import React from 'react'
import no_profile_placeholder from '../images/no-profile.svg'
import Lightbox from 'react-image-lightbox'

export default class EachResult extends React.Component {
  constructor() {
    super()
    this.state = {
      popImage: false,
    }
  }

  formatMobileNumber = phoneNumber => {
    return phoneNumber.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
  }

  render() {
    const user = this.props.user
    return (
      <div className="fd-center each-match">
        <div className="card">
          <img
            className="match-profile"
            style={{ width: '90px', height: '90px' }}
            src={user.photo_url ? user.photo_url : no_profile_placeholder}
            onClick={() => this.setState({ popImage: true })}
          />
          {this.state.popImage && (
            <Lightbox
              mainSrc={user.photo_url ? user.photo_url : no_profile_placeholder}
              onCloseRequest={() => {
                this.setState({ popImage: false })
              }}
            />
          )}
          <h2 className="title is-6">
            {user.first_name}
          </h2>
          {user.public_contacts &&
            user.public_contacts.search('mobile') !== -1 && (
              <p>
                <a href={'tel:' + this.formatMobileNumber(user.mobile)}>
                  {this.formatMobileNumber(user.mobile)}
                </a>
              </p>
            )}
          {user.public_contacts && user.public_contacts.search('email') !== -1 && (
            <p style={{ overflow: 'hidden' }}>
              <a href={'mailto:' + user.email}>{user.email.toLowerCase()}</a>
            </p>
          )}

          {window.location.pathname == '/matches-overview' ? (
            <button
              class="btn-delete"
              onClick={() => this.props.deleteMatch(user)}
            >
              Delete
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}
