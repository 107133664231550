import React from 'react'
import Layout from '../components/layout'

import EventResults from '../components/event-results'

const EventResultsPage = () => (
    <Layout>
        <EventResults />
    </Layout>
)

export default EventResultsPage