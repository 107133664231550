import React, { Component } from 'react'
import axios from 'axios'
import * as storage from '../utils/storage'
import EachResult from './each-result'

class EventResults extends Component {
    state = { users: [] }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search)
        const eventId = urlParams.get('id')

        let currentUser = storage.getItem('user')
        currentUser = currentUser ? JSON.parse(currentUser) : currentUser
        axios.post(`${process.env.API_BASE_URL}/graphql`, {
        query: `query {
            event(id: ${eventId}) {
            name
            status
            matchesForUser(id: ${currentUser.id}) {
                email
                first_name
                last_name
                age
                mobile
                location
                photo_url
                public_contacts
            }
            }
        }`
        })
        .then(res => {
        this.setState({
            users: res.data.data.event.matchesForUser
        })
        })
        .catch(err => console.log(err))
    }

    render() {
        return (
            <div>
                <h2 className="has-text-centered title is-2">My Matches</h2>
                <div className="past-events result">
                    {this.state.users.map((user, i) => {
                    return <EachResult key={i} user={user}></EachResult>
                    })}
                </div>
            </div>
          )
    }
}

export default EventResults
